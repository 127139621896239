<template>
  <Layout class="container">
    <article class="devsite-article mb-5">
      <div class="devsite-article-meta nocontent" role="navigation">
        <ul class="devsite-breadcrumb-list"></ul>
      </div>
      <h1 class="devsite-page-title">Google APIs Terms of Service</h1>
      <div class="devsite-article-body clearfix">
        <p>Thank you for using Google's APIs, other developer services, and associated
          software (collectively, "APIs"). By accessing or using our APIs, you are
          agreeing to the terms below. If there is a conflict between these terms and
          additional terms applicable to a given API, the additional terms will control
          for that conflict. Collectively, we refer to the terms below, any additional
          terms, terms within the accompanying API documentation, and any applicable
          policies and guidelines as the "Terms." You agree to comply with the Terms and
          that the Terms control your relationship with us. So please read all the Terms
          carefully. If you use the APIs as an interface to, or in conjunction with other
          Google products or services, then the terms for those other products or services
          also apply.</p>

        <p>Under the Terms, "Google" means Google LLC, with offices at 1600 Amphitheatre
          Parkway, Mountain View, California 94043, United States, unless set forth
          otherwise in additional terms applicable for a given API. We may refer to
          "Google" as "we", "our", or "us" in the Terms.</p>

        <h2 id="section_1_account_and_registration" data-text="Section 1: Account and Registration" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="2">Section 1: Account and Registration</span>
        </h2>

        <h3 id="a_accepting_the_terms" data-text="a. Accepting the Terms" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">a.<wbr> Accepting the Terms</span>
        </h3>

        <p>You may not use the APIs and may not accept the Terms if (a) you are not of
          legal age to form a binding contract with Google, or (b) you are a person barred
          from using or receiving the APIs under the applicable laws of the United States
          or other countries including the country in which you are resident or from which
          you use the APIs.</p>

        <h3 id="b_entity_level_acceptance" data-text="b. Entity Level Acceptance" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">b.<wbr> Entity Level Acceptance</span>
        </h3>

        <p>If you are using the APIs on behalf of an entity, you represent and warrant that
          you have authority to bind that entity to the Terms and by accepting the Terms,
          you are doing so on behalf of that entity (and all references to "you" in the
          Terms refer to that entity).</p>

        <h3 id="c_registration" data-text="c. Registration" role="presentation"><span class="devsite-heading"
                                                                                      role="heading" aria-level="3">c.<wbr> Registration</span>
        </h3>

        <p>In order to access certain APIs you may be required to provide certain
          information (such as identification or contact details) as part of the
          registration process for the APIs, or as part of your continued use of the APIs.
          Any registration information you give to Google will always be accurate and up
          to date and you'll inform us promptly of any updates.</p>

        <h3 id="d_subsidiaries_and_affiliates" data-text="d. Subsidiaries and Affiliates" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">d.<wbr> Subsidiaries and Affiliates</span>

        </h3>

        <p>Google has subsidiaries and affiliated legal entities around the world. These
          companies may provide the APIs to you on behalf of Google and the Terms will
          also govern your relationship with these companies.</p>

        <h2 id="section_2_using_our_apis" data-text="Section 2: Using Our APIs" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="2">Section 2: Using Our APIs</span>

        </h2>

        <h3 id="a_your_end_users" data-text="a. Your End Users" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">a.<wbr> Your End Users</span>
        </h3>

        <p>You will require your end users to comply with (and not knowingly enable them to
          violate) applicable law, regulation, and the Terms.</p>

        <h3 id="b_compliance_with_law_third_party_rights_and_other_google_terms_of_service"
            data-text="b. Compliance with Law, Third Party Rights, and Other Google Terms of Service"
            role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">b.
            <wbr> Compliance with Law,<wbr> Third Party Rights,<wbr> and Other Google Terms of Service</span>
        </h3>

        <p>You will comply with all applicable law, regulation, and third party rights
          (including without limitation laws regarding the import or export of data or
          software, privacy, and local laws). You will not use the APIs to encourage or
          promote illegal activity or violation of third party rights. You will not
          violate any other terms of service with Google (or its affiliates).</p>

        <h3 id="c_permitted_access" data-text="c. Permitted Access" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">c.<wbr> Permitted Access</span>
        </h3>

        <p>You will only access (or attempt to access) an API by the means described in the
          documentation of that API. If Google assigns you developer credentials (e.g.
          client IDs), you must use them with the applicable APIs. You will not
          misrepresent or mask either your identity or your API Client's identity when
          using the APIs or developer accounts.</p>

        <h3 id="d_api_limitations" data-text="d. API Limitations" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">d.<wbr> API Limitations</span>
        </h3>

        <p>Google sets and enforces limits on your use of the APIs (e.g. limiting the
          number of API requests that you may make or the number of users you may serve),
          in our sole discretion. You agree to, and will not attempt to circumvent, such
          limitations documented with each API. If you would like to use any API beyond
          these limits, you must obtain Google's express consent (and Google may decline
          such request or condition acceptance on your agreement to additional terms
          and/or charges for that use). To seek such approval, contact the relevant Google
          API team for information (e.g. by using the Google developers console).</p>

        <h3 id="e_open_source_software" data-text="e. Open Source Software" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">e.<wbr> Open Source Software</span>
        </h3>

        <p>Some of the software required by or included in our APIs may be offered under an
          open source license. Open source software licenses constitute separate written
          agreements. For certain APIs, open source software is listed in the
          documentation. To the limited extent the open source software license expressly
          supersedes the Terms, the open source license instead sets forth your agreement
          with Google for the applicable open source software.</p>

        <h3 id="f_communication_with_google" data-text="f. Communication with Google" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">f.<wbr> Communication with Google</span>
        </h3>

        <p>We may send you certain communications in connection with your use of the APIs.
          Please review the applicable API documentation for information about opting out
          of certain types of communication.</p>

        <h3 id="g_feedback" data-text="g. Feedback" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">g.<wbr> Feedback</span>
        </h3>

        <p>If you provide feedback or suggestions about our APIs, then we (and those we
          allow) may use such information without obligation to you.</p>

        <h3 id="h_non-exclusivity" data-text="h. Non-Exclusivity" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">h.<wbr> Non-Exclusivity</span>
        </h3>

        <p>The Terms are non-exclusive. You acknowledge that Google may develop products or
          services that may compete with the API Clients or any other products or
          services.</p>

        <h3 id="i_google_controller-controller_data_protection_terms"
            data-text="i. Google Controller-Controller Data Protection Terms" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">i.<wbr> Google Controller-Controller Data Protection Terms</span>

        </h3>

        <p>To the extent required by data protection laws applicable to the parties'
          processing of personal data under these Terms, the parties agree to the
          <a href="https://privacy.google.com/businesses/gdprcontrollerterms">Google Controller-Controller Data
            Protection Terms</a>.</p>

        <h2 id="section_3_your_api_clients" data-text="Section 3: Your API Clients" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="2">Section 3: Your API Clients</span>

        </h2>

        <h3 id="a_api_clients_and_monitoring" data-text="a. API Clients and Monitoring" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">a.<wbr> API Clients and Monitoring</span>
        </h3>

        <p>The APIs are designed to help you enhance your websites and applications ("API
          Client(s)"). YOU AGREE THAT GOOGLE MAY MONITOR USE OF THE APIS TO ENSURE
          QUALITY, IMPROVE GOOGLE PRODUCTS AND SERVICES, AND VERIFY YOUR COMPLIANCE WITH
          THE TERMS. This monitoring may include Google accessing and using your API
          Client, for example to identify security issues that could affect Google or its
          users. You will not interfere with this monitoring. Google may use any technical
          means to overcome such interference. Google may suspend access to the APIs by
          you or your API Client without notice if we reasonably believe that you are in
          violation of the Terms.</p>

        <h3 id="b_security" data-text="b. Security" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">b.<wbr> Security</span>
        </h3>

        <p>You will use commercially reasonable efforts to protect user information
          collected by your API Client, including personal data, from unauthorized access or use and will promptly
          report to your users
          any unauthorized access or use of such information to the extent required by
          applicable law.</p>

        <h3 id="c_ownership" data-text="c. Ownership" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">c.<wbr> Ownership</span>
        </h3>

        <p>Google does not acquire ownership in your API Clients, and by using our APIs,
          you do not acquire ownership of any rights in our APIs or the content that is
          accessed through our APIs.</p>

        <h3 id="d_user_privacy_and_api_clients" data-text="d. User Privacy and API Clients" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">d.<wbr> User Privacy and API Clients</span>
        </h3>

        <p>
          You will comply with (1) all applicable privacy laws and regulations including
          those applying to personal data and (2) the
          <a href="/terms/api-services-user-data-policy">Google API Services User Data Policy</a>,
          which governs your use of the APIs when you request access to Google user
          information. You will provide and adhere to a privacy policy for your API Client
          that clearly and accurately describes to users of your API Client what user
          information you collect and how you use and share such information (including
          for advertising) with Google and third parties.
        </p>

        <h2 id="section_4_prohibitions_and_confidentiality" data-text="Section 4: Prohibitions and Confidentiality" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="2">Section 4: Prohibitions and Confidentiality</span>
        </h2>

        <h3 id="a_api_prohibitions" data-text="a. API Prohibitions" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">a.<wbr> API Prohibitions</span>

        </h3>

        <p>When using the APIs, you may not (or allow those acting on your behalf to):</p>

        <ol>
          <li>Sublicense an API for use by a third party. Consequently, you will not
            create an API Client that functions substantially the same as the APIs and
            offer it for use by third parties.
          </li>
          <li>Perform an action with the intent of introducing to Google products and
            services any viruses, worms, defects, Trojan horses, malware, or any items
            of a destructive nature.
          </li>
          <li>Defame, abuse, harass, stalk, or threaten others.</li>
          <li>Interfere with or disrupt the APIs or the servers or networks providing the
            APIs.
          </li>
          <li>Promote or facilitate unlawful online gambling or disruptive commercial
            messages or advertisements.
          </li>
          <li>Reverse engineer or attempt to extract the source code from any API or any
            related software, except to the extent that this restriction is expressly
            prohibited by applicable law.
          </li>
          <li>Use the APIs for any activities where the use or failure of the APIs could
            lead to death, personal injury, or environmental damage (such as the
            operation of nuclear facilities, air traffic control, or life support
            systems).
          </li>
          <li>Use the APIs to process or store any data that is subject to the
            International Traffic in Arms Regulations maintained by the U.S. Department
            of State.
          </li>
          <li>Remove, obscure, or alter any Google terms of service or any links to or
            notices of those terms.
          </li>
        </ol>

        <p>Unless otherwise specified in writing by Google, Google does not intend use of
          the APIs to create obligations under the Health Insurance Portability and
          Accountability Act, as amended ("HIPAA"), and makes no representations that the
          APIs satisfy HIPAA requirements. If you are (or become) a "covered entity" or
          "business associate" as defined in HIPAA, you will not use the APIs for any
          purpose or in any manner involving transmitting protected health information to
          Google unless you have received prior written consent to such use from Google.</p>

        <h3 id="b_confidential_matters" data-text="b. Confidential Matters" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">b.<wbr> Confidential Matters</span>
        </h3>

        <ol>
          <li>Developer credentials (such as passwords, keys, and client IDs) are intended
            to be used by you and identify your API Client. You will keep your
            credentials confidential and make reasonable efforts to prevent and
            discourage other API Clients from using your credentials. Developer
            credentials may not be embedded in open source projects.
          </li>
          <li>Our communications to you and our APIs may contain Google confidential
            information. Google confidential information includes any materials,
            communications, and information that are marked confidential or that would
            normally be considered confidential under the circumstances. If you receive
            any such information, then you will not disclose it to any third party
            without Google's prior written consent. Google confidential information does
            not include information that you independently developed, that was
            rightfully given to you by a third party without confidentiality obligation,
            or that becomes public through no fault of your own. You may disclose Google
            confidential information when compelled to do so by law if you provide us
            reasonable prior notice, unless a court orders that we not receive notice.
          </li>
        </ol>

        <h2 id="section_5_content" data-text="Section 5: Content" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="2">Section 5: Content</span>

        </h2>

        <h3 id="a_content_accessible_through_our_apis" data-text="a. Content Accessible Through our APIs"
            role="presentation"><span class="devsite-heading" role="heading" aria-level="3">a.<wbr> Content Accessible Through our APIs</span>
        </h3>

        <p>Our APIs contain some third party content (such as text, images, videos, audio,
          or software). This content is the sole responsibility of the person that makes
          it available. We may sometimes review content to determine whether it is illegal
          or violates our policies or the Terms, and we may remove or refuse to display
          content. Finally, content accessible through our APIs may be subject to
          intellectual property rights, and, if so, you may not use it unless you are
          licensed to do so by the owner of that content or are otherwise permitted by
          law. Your access to the content provided by the API may be restricted, limited,
          or filtered in accordance with applicable law, regulation, and policy.</p>

        <h3 id="b_submission_of_content" data-text="b. Submission of Content" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">b.<wbr> Submission of Content</span>
        </h3>

        <p>Some of our APIs allow the submission of content. Google does not acquire any
          ownership of any intellectual property rights in the content that you submit to
          our APIs through your API Client, except as expressly provided in the Terms. For
          the sole purpose of enabling Google to provide, secure, and improve the APIs
          (and the related service(s)) and only in accordance with the applicable Google
          privacy policies, you give Google a perpetual, irrevocable, worldwide,
          sublicensable, royalty-free, and non-exclusive license to Use content submitted,
          posted, or displayed to or from the APIs through your API Client. "Use" means
          use, host, store, modify, communicate, and publish. Before you submit content to
          our APIs through your API Client, you will ensure that you have the necessary
          rights (including the necessary rights from your end users) to grant us the
          license.</p>

        <h3 id="c_retrieval_of_content" data-text="c. Retrieval of content" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">c.<wbr> Retrieval of content</span>
        </h3>

        <p>When a user's non-public content is obtained through the APIs, you may not
          expose that content to other users or to third parties without explicit opt-in
          consent from that user.</p>

        <h3 id="d_data_portability" data-text="d. Data Portability" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">d.<wbr> Data Portability</span>
        </h3>

        <p>Google supports data portability. For as long as you use or store any user data
          that you obtained through the APIs, you agree to enable your users to export
          their equivalent data to other services or applications of their choice in a way
          that's substantially as fast and easy as exporting such data from Google
          products and services, subject to applicable laws, and you agree that you will
          not make that data available to third parties who do not also abide by this
          obligation.</p>

        <h3 id="e_prohibitions_on_content" data-text="e. Prohibitions on Content" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">e.<wbr> Prohibitions on Content</span>

        </h3>

        <p>Unless expressly permitted by the content owner or by applicable law, you will
          not, and will not permit your end users or others acting on your behalf to, do
          the following with content returned from the APIs:</p>

        <ol>
          <li>Scrape, build databases, or otherwise create permanent copies of such
            content, or keep cached copies longer than permitted by the cache header;
          </li>
          <li>Copy, translate, modify, create a derivative work of, sell, lease, lend,
            convey, distribute, publicly display, or sublicense to any third party;
          </li>
          <li>Misrepresent the source or ownership; or</li>
          <li>Remove, obscure, or alter any copyright, trademark, or other proprietary
            rights notices; or falsify or delete any author attributions, legal notices,
            or other labels of the origin or source of material.
          </li>
        </ol>

        <h2 id="section_6_brand_features_attribution" data-text="Section 6: Brand Features; Attribution" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="2">Section 6: Brand Features; Attribution</span>
        </h2>

        <h3 id="a_brand_features" data-text="a. Brand Features" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">a.<wbr> Brand Features</span>
        </h3>

        <p>"Brand Features" is defined as the trade names, trademarks, service marks,
          logos, domain names, and other distinctive brand features of each party. Except
          where expressly stated, the Terms do not grant either party any right, title, or
          interest in or to the other party's Brand Features. All use by you of Google's
          Brand Features (including any goodwill associated therewith) will inure to the
          benefit of Google.</p>

        <h3 id="b_attribution" data-text="b. Attribution" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">b.<wbr> Attribution</span>
        </h3>

        <p>You agree to display any attribution(s) required by Google as described in the
          documentation for the API. Google hereby grants to you a nontransferable,
          nonsublicenseable, nonexclusive license while the Terms are in effect to display
          Google's Brand Features for the purpose of promoting or advertising that you use
          the APIs. You must only use the Google Brand Features in accordance with the
          Terms and for the purpose of fulfilling your obligations under this Section. In
          using Google's Brand Features, you must follow the <a
              href="https://www.google.com/permissions/guidelines.html">Google Brand Features Use
            Guidelines</a>. You understand
          and agree that Google has the sole discretion to determine whether your
          attribution(s) and use of Google's Brand Features are in accordance with the
          above requirements and guidelines.</p>

        <h3 id="c_publicity" data-text="c. Publicity" role="presentation"><span class="devsite-heading" role="heading"
                                                                                aria-level="3">c.<wbr> Publicity</span>

        </h3>

        <p>You will not make any statement regarding your use of an API which suggests
          partnership with, sponsorship by, or endorsement by Google without Google's
          prior written approval.</p>

        <h3 id="d_promotional_and_marketing_use" data-text="d. Promotional and Marketing Use" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">d.<wbr> Promotional and Marketing Use</span>

        </h3>

        <p>In the course of promoting, marketing, or demonstrating the APIs you are using
          and the associated Google products, Google may produce and distribute incidental
          depictions, including screenshots, video, or other content from your API Client,
          and may use your company or product name. You grant us all necessary rights for
          the above purposes.</p>

        <h2 id="section_7_privacy_and_copyright_protection" data-text="Section 7: Privacy and Copyright Protection"
            role="presentation"><span class="devsite-heading" role="heading" aria-level="2">Section 7: Privacy and Copyright Protection</span>

        </h2>

        <h3 id="a_google_privacy_policies" data-text="a. Google Privacy Policies" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">a.<wbr> Google Privacy Policies</span>

        </h3>

        <p>By using our APIs, Google may use submitted information in accordance with our
          <a href="https://www.google.com/policies/privacy/">privacy policies</a>.</p>

        <h3 id="b_google_dmca_policy" data-text="b. Google DMCA Policy" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">b.<wbr> Google DMCA Policy</span>

        </h3>

        <p>We provide information to help copyright holders manage their intellectual
          property online, but we can't determine whether something is being used legally
          or not without their input. We respond to notices of alleged copyright
          infringement and terminate accounts of repeat infringers according to the
          process set out in the U.S. Digital Millennium Copyright Act. If you think
          somebody is violating your copyrights and want to notify us, you can find
          information about submitting notices and Google's policy about responding to
          notices in our <a href="https://www.google.com/dmca.html">Help Center</a>.</p>

        <h2 id="section_8_termination" data-text="Section 8: Termination" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="2">Section 8: Termination</span>
        </h2>

        <h3 id="a_termination" data-text="a. Termination" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">a.<wbr> Termination</span>
        </h3>

        <p>You may stop using our APIs at any time with or without notice. Further, if you
          want to terminate the Terms, you must provide Google with prior written notice
          and upon termination, cease your use of the applicable APIs. Google reserves the
          right to terminate the Terms with you or discontinue the APIs or any portion or
          feature or your access thereto for any reason and at any time without liability
          or other obligation to you.</p>

        <h3 id="b_your_obligations_post-termination" data-text="b. Your Obligations Post-Termination"
            role="presentation"><span class="devsite-heading" role="heading" aria-level="3">b.<wbr> Your Obligations Post-Termination</span>
        </h3>

        <p>Upon any termination of the Terms or discontinuation of your access to an API,
          you will immediately stop using the API, cease all use of the Google Brand
          Features, and delete any cached or stored content that was permitted by the
          cache header under Section 5. Google may independently communicate with any
          account owner whose account(s) are associated with your API Client and developer
          credentials to provide notice of the termination of your right to use an API.</p>

        <h3 id="c_surviving_provisions" data-text="c. Surviving Provisions" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">c.<wbr> Surviving Provisions</span>

        </h3>

        <p>When the Terms come to an end, those terms that by their nature are intended to
          continue indefinitely will continue to apply, including but not limited to:
          Sections 4b, 5, 8, 9, and 10.</p>

        <h2 id="section_9_liability_for_our_apis" data-text="Section 9: Liability for our APIs" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="2">Section 9: Liability for our APIs</span>

        </h2>

        <h3 id="a_warranties" data-text="a. WARRANTIES" role="presentation"><span class="devsite-heading" role="heading"
                                                                                  aria-level="3">a.<wbr> WARRANTIES</span>

        </h3>

        <p>EXCEPT AS EXPRESSLY SET OUT IN THE TERMS, NEITHER GOOGLE NOR ITS SUPPLIERS OR
          DISTRIBUTORS MAKE ANY SPECIFIC PROMISES ABOUT THE APIS. FOR EXAMPLE, WE DON'T
          MAKE ANY COMMITMENTS ABOUT THE CONTENT ACCESSED THROUGH THE APIS, THE SPECIFIC
          FUNCTIONS OF THE APIS, OR THEIR RELIABILITY, AVAILABILITY, OR ABILITY TO MEET
          YOUR NEEDS. WE PROVIDE THE APIS "AS IS".</p>

        <p>SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
          EXCEPT AS EXPRESSLY PROVIDED FOR IN THE TERMS, TO THE EXTENT PERMITTED BY LAW,
          WE EXCLUDE ALL WARRANTIES, GUARANTEES, CONDITIONS, REPRESENTATIONS, AND
          UNDERTAKINGS.</p>

        <h3 id="b_limitation_of_liability" data-text="b. LIMITATION OF LIABILITY" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">b.<wbr> LIMITATION OF LIABILITY</span>

        </h3>

        <p>WHEN PERMITTED BY LAW, GOOGLE, AND GOOGLE'S SUPPLIERS AND DISTRIBUTORS, WILL
          NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES, OR DATA; FINANCIAL LOSSES; OR
          INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES.</p>

        <p>TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF GOOGLE, AND ITS
          SUPPLIERS AND DISTRIBUTORS, FOR ANY CLAIM UNDER THE TERMS, INCLUDING FOR ANY
          IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE APPLICABLE
          APIS (OR, IF WE CHOOSE, TO SUPPLYING YOU THE APIS AGAIN) DURING THE SIX MONTHS
          PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY.</p>

        <p>IN ALL CASES, GOOGLE, AND ITS SUPPLIERS AND DISTRIBUTORS, WILL NOT BE LIABLE
          FOR ANY EXPENSE, LOSS, OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.</p>

        <h3 id="c_indemnification" data-text="c. Indemnification" role="presentation"><span class="devsite-heading"
                                                                                            role="heading"
                                                                                            aria-level="3">c.<wbr> Indemnification</span>

        </h3>

        <p>Unless prohibited by applicable law, if you are a business, you will defend and
          indemnify Google, and its affiliates, directors, officers, employees, and users,
          against all liabilities, damages, losses, costs, fees (including legal fees),
          and expenses relating to any allegation or third-party legal proceeding to the
          extent arising from:</p>

        <ol>
          <li>your misuse or your end user's misuse of the APIs;</li>
          <li>your violation or your end user's violation of the Terms; or</li>
          <li>any content or data routed into or used with the APIs by you, those acting
            on your behalf, or your end users.
          </li>
        </ol>

        <h2 id="section_10_general_provisions" data-text="Section 10: General Provisions" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="2">Section 10: General Provisions</span>

        </h2>

        <h3 id="a_modification" data-text="a. Modification" role="presentation">
          <span class="devsite-heading" role="heading" aria-level="3">a.<wbr> Modification</span>
        </h3>

        <p>We may modify the Terms or any portion to, for example, reflect changes to the
          law or changes to our APIs. You should look at the Terms regularly. We'll post
          notice of modifications to the Terms within the documentation of each applicable
          API, to this website, and/or in the Google developers console. Changes will not
          apply retroactively and will become effective no sooner than 30 days after they
          are posted. But changes addressing new functions for an API or changes made for
          legal reasons will be effective immediately. If you do not agree to the modified
          Terms for an API, you should discontinue your use of that API. Your continued
          use of the API constitutes your acceptance of the modified Terms.</p>

        <h3 id="b_us_federal_agency_entities" data-text="b. U.S. Federal Agency Entities" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">b.<wbr> U.<wbr>S.<wbr> Federal Agency Entities</span>
        </h3>

        <p>The APIs were developed solely at private expense and are commercial computer
          software and related documentation within the meaning of the applicable U.S.
          Federal Acquisition Regulation and agency supplements thereto.</p>

        <h3 id="c_general_legal_terms" data-text="c. General Legal Terms" role="presentation"><span
            class="devsite-heading" role="heading" aria-level="3">c.<wbr> General Legal Terms</span>

        </h3>

        <p>We each agree to contract in the English language. If we provide a translation
          of the Terms, we do so for your convenience only and the English Terms will
          solely govern our relationship. The Terms do not create any third party
          beneficiary rights or any agency, partnership, or joint venture. Nothing in the
          Terms will limit either party's ability to seek injunctive relief. We are not
          liable for failure or delay in performance to the extent caused by circumstances
          beyond our reasonable control. If you do not comply with the Terms, and Google
          does not take action right away, this does not mean that Google is giving up any
          rights that it may have (such as taking action in the future). If it turns out
          that a particular term is not enforceable, this will not affect any other terms.
          The Terms are the entire agreement between you and Google relating to its
          subject and supersede any prior or contemporaneous agreements on that subject.
          For information about how to contact Google, please visit our <a
              href="https://www.google.com/intl/en/contact/">contact
            page</a>.</p>

        <p>Except as set forth below: (i) the laws of California, U.S.A., excluding
          California's conflict of laws rules, will apply to any disputes arising out of
          or related to the Terms or the APIs and (ii) ALL CLAIMS ARISING OUT OF OR
          RELATING TO THE TERMS OR THE APIS WILL BE LITIGATED EXCLUSIVELY IN THE FEDERAL
          OR STATE COURTS OF SANTA CLARA COUNTY, CALIFORNIA, USA, AND YOU AND GOOGLE
          CONSENT TO PERSONAL JURISDICTION IN THOSE COURTS.</p>

        <p>If you are accepting the Terms on behalf of a United States federal government
          entity, then the following applies instead of the paragraph above: the laws of
          the United States of America, excluding its conflict of laws rules, will apply
          to any disputes arising out of or related to the Terms or the APIs. Solely to
          the extent permitted by United States Federal law: (i) the laws of the State of
          California (excluding California's conflict of laws rules) will apply in the
          absence of applicable federal law; and (ii) FOR ALL CLAIMS ARISING OUT OF OR
          RELATING TO THE TERMS OR THE APIS, THE PARTIES CONSENT TO PERSONAL JURISDICTION
          IN, AND THE EXCLUSIVE VENUE OF, THE COURTS IN SANTA CLARA COUNTY, CALIFORNIA.</p>

        <p>If you are accepting the Terms on behalf of a United States city, county, or
          state government entity, then the following applies instead of the paragraph
          above: the parties agree to remain silent regarding governing law and venue.</p>
      </div>
    </article>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main"

export default {
  name: "terms-of-service",
  components: {
    Layout
  }
}
</script>

<style scoped>

</style>